import { axiosGet, axiosPost } from '../lib/api';
import { useEffect, useState } from 'react';
import RenderRazorpay from '../Components/RenderRazorpay';
import PricingToast from '../Components/PricingToast';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { keyId, KeySecret } from '../lib/config';

export default function Pricing() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const userId = params.get('d');

  const [data, setData] = useState([]);
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [eventFee, setEventFee] = useState('')
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });
  const [show, setShow] = useState(false);
  const [sMsg, setSMsg] = useState("");

  // useEffect(() => {
  // const userID = localStorage.getItem('userId');

  const fetchAppAttendee = () => {
    axiosGet
      .get(
        `attendee/id/web`,
        {
          params: {
            dataUniqId: userId,
          }
        }
      )
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchEventData = () => {
    axiosGet
      .get(`eventsection/get/web`)
      .then((response) => {
        let data = response.data.data[0];
        // setEventData(data);
        setEventFee(data?.eventFee)

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (userId) {
      fetchEventData();
      fetchAppAttendee();
    } else {
      navigate('/')
    }
  }, [userId]);

  const handleSave = () => {
    setShow(true);
    setSMsg('Successfully registered & will notify about the event');
    setTimeout(() => {
      navigate("/");
    }, 5000);
  }

  const handleCreateOrder = () => {

    const json = {
      amount: eventFee * 100,
      currency: 'INR',
      keyId: keyId,
      KeySecret: KeySecret,
      // keyId: 'rzp_test_49pMyOx78pcs53',
      // KeySecret: 'pjeMjZLNyvjWfqKFIUyUp8st',

    }
    axiosPost.post('event/fee/payment', json)
      .then((res) => {
        let data = res.data
        setOrderDetails({
          orderId: data.id,
          currency: data.currency,
          amount: data.amount,
        });
        setDisplayRazorpay(true);
        setTimeout(() => {
          setDisplayRazorpay(false);
        }, 3000);
      })
  };

  return (
    <div className='h-[79vh] grid place-items-center px-4 md:px-0'>
      <div class="relative py-6 px-8 rounded-lg bg-[#fff4eb] w-full md:w-6/12 mt-6 md:mb-0">
        <div class="flex justify-between">
          <div class="w-full">
            <div class="text-blue-900 text-2xl font-semibold mb-2">Registration</div>
            <div class="text-dark-2 text-sm leading-tight">Entry pass covers</div>
          </div>
          <div className="text-dark-1 text-4xl leading-none text-right">
            {eventFee ? (
              <>
                <span className="flex gap-1 items-center">
                  <span className="font-sans">&#8377;</span>
                  {/* {eventFee ? ( */}

                  <span className="text-3xl"> {eventFee}</span>

                  {/* ) : (
                <span className="text-sm text-gray-500 whitespace-nowrap">--</span>
              )} */}
                </span>
                <span className="ml-1 text-dark-2 text-base block">/Attendee</span>
              </>
            ) : null}
          </div>
        </div>
        <div class="border-b border-light-2 w-full mt-6 mb-8"></div>
        <ul class="mb-8">
          <li class="flex items-center gap-1 text-blue-900 text-sm font-medium mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
              <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
            <span>Attend all Conference Events
            </span>
          </li>
          <li class="flex items-center gap-1 text-blue-900 text-sm font-medium mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
              <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
            <span>Conference Papers and Case Studies
            </span>
          </li>
          <li class="flex items-center gap-1 text-blue-900 text-sm font-medium mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
              <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
            <span>Conference Exhibition and Vendor Booths
            </span>
          </li>
          <li class="flex items-center gap-1 text-blue-900 text-sm font-medium mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
              <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
            <span>Tea/Coffee Breaks
            </span>
          </li>
          <li class="flex items-center gap-1 text-blue-900 text-sm font-medium mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
              <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
            <span>Attend Sponsored Lunch/Reception Sessions
            </span>
          </li>
        </ul>
        <div className='flex'>
          <button
            onClick={!eventFee ? handleSave : handleCreateOrder}
            //  onClick={handleCreateOrder}
            rel="noopener noreferrer"
            className="inline-flex h-10 mx-auto animate-shimmer items-center justify-center rounded-md border  bg-[linear-gradient(110deg,#FF6500,45%,#ff6600d4,55%,#ff66009f)] bg-[length:200%_100%] px-6 font-medium text-slate-50 transition-colors"
          // className="flex animate-shimmer justify-center duration-300 w-full mx-auto py-3 mt-1 text-sm font-medium bg-blue-400 text-white border hover:border-blue-400 rounded-xl active:text-orange-500 hover:bg-transparent hover:text-blue-400 focus:outline-none focus:ring-0"
          >
            {!eventFee ? "Save & Continue" : "Get started now"}

          </button>
        </div>
      </div>
      {
        displayRazorpay &&
        <RenderRazorpay
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          keyId={process.env.REACT_APP_RAZORPAY_KEY_ID}
          keySecret={process.env.REACT_APP_RAZORPAY_KEY_SECRET}
          data={data}
          setShow={setShow}
          setSMsg={setSMsg}
        />
      }
      <PricingToast msg={sMsg} show={show} setShow={setShow} />
    </div>
  )
}
