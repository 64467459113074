"use client";
import React from "react";
import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
} from "@headlessui/react";
import { IoClose } from "react-icons/io5";
import { BsLinkedin } from "react-icons/bs";

export default function BioData({ open, setOpen, data, type }) {
  // const widthClass = type === 1 ? "w-[50%]" : "max-w-xl";
  const dialogClass = `relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:p-6 data-[closed]:sm:translate-y-0 min-w-lg data-[closed]:sm:scale-95`;
  // ${widthClass}
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="relative z-30"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <DialogPanel transition className={dialogClass}>
            {/* <h3 className="font-bold text-center text-xl text-black block pb-5">
              Bio Data
            </h3> */}

            <CloseButton
              className={"absolute right-5 top-5 text-lg text-red-600"}
            >
              <IoClose />
            </CloseButton>
            <div
              className="text-center global-title my-5"
              style={{ fontFamily: "custom" }}
            >
              <h2 className="inline-block font-bold text-xl text-black cursor-pointor">
                {type === 1
                  ? "Speaker Data"
                  : type === 2
                  ? "Attendee Data"
                  : "Bio Data"}
              </h2>
            </div>
            <div className="flex flex-col items-center gap-2 min-w-[32rem]">
              <img
                src={data?.photo}
                alt=""
                className="w-24 h-24 rounded-full ring-2 ring-[#ff6500]"
                srcSet=""
              />
              <div className="flex items-center gap-2">
              <h3
                className="text-[#FF6500] text-2xl italic font-semibold block capitalize"
                style={{ fontFamily: "custom" }}
              >
                {data?.firstName} {data?.lastName}
              </h3>
              <button
                aria-label=""
                onClick={() => window.open(data.linkedInUrl, "_blank")}
              >
                <BsLinkedin />
              </button>
              </div>
              {/* <div className="flex items-center gap-1"> */}
                {data.role ? (
                  <h6 className="text-sm block text-[#666666] capitalize">
                    Designation : {data.role}
                  </h6>
                ) : null}
                {/* {" || "} */}
                {data.company ? (
                  <h6 className="text-sm block text-[#666666] capitalize">
                    Organization : {data.company}
                  </h6>
                ) : null}
              {/* </div> */}
              {/* 
              {data.industryName ? (
                <h6 className="text-sm block text-[#666666] capitalize">
                  Industry : {data.industryName}
                </h6>
              ) : null}
              {data.phoneNumber ? (
                <h6 className="text-sm block text-[#666666]">
                  Mobile :{" "}
                  <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
                </h6>
              ) : null}
              {data.email ? (
                <h6 className="text-sm block text-[#666666]">
                  Email :{" "}
                  <a href={`mailto:${data.email}`} className="lowercase">
                    {data.email}
                  </a>
                </h6>
              ) : null} */}
             
             
              {/* {data.linkedInUrl ? (
                <h6
                  className="text-sm block text-[#666666]  w-80 truncate line-clamp-2"
                  title={data.linkedInUrl}
                >
                  LinkedIn :{" "}
                  <a
                    href={data.linkedInUrl}
                    // href="https://www.linkedin.com/in/username"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="lowercase"
                  >
                    {data.linkedInUrl}
                  </a>
                </h6>
              ) : null} */}
              {/* {data?.areaOfInterest ? (
                <h6 className="text-sm block text-[#666666]">
                  Interested in{" "}
                  {data?.areaOfInterest === "1"
                    ? "Conditional Monitoring"
                    : data?.areaOfInterest === "2"
                    ? "Reliability"
                    : data?.areaOfInterest === "3"
                    ? "Both"
                    : "---"}
                </h6>
              ) : null}
              {data?.preference ? (
                <h6 className="text-sm block text-[#666666]">
                  Preference :{data?.preference.join(", ")}
                </h6>
              ) : (
                "---"
              )} 
              {data?.introduction ? (
                <p className="text-sm block text-[#666666] capitalize max-h-44 aScrollBar">
                  Introduction :
                  <div
                    style={{ maxHeight: "160px", overflow: "auto" }}
                    className="aScrollBar"
                    dangerouslySetInnerHTML={{ __html: data.introduction }}
                  />
                </p>
              ) : null}*/}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
