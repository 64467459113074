// App.jsx

import { Routes, Route } from 'react-router-dom';
import Navbar from './Components/Layout/Navbar';
import Footer from './Components/Layout/Footer';
import Home from './Pages/home';
import PrivacyPolicy from './Pages/privacy_policy';
import NotFound from './Pages/not-found';
import Imprint from './Pages/imprint';
import TermsAndConditions from './Pages/terms';
import Pricing from './Pages/Pricing';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
