import React, { useState, useEffect, useRef, useCallback } from "react";
import Attendee from "../Modals/RegisterModal/Attendee";
import Speaker from "../Modals/RegisterModal/Speaker";
import Readmore from "../Modals/Readmore";
import { axiosGet } from "../../lib/api";
import { PrevButton, NextButton, usePrevNextButtons } from "../ArrowButtons";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { API_ENDPOINT_IMG } from "../../lib/config";

// Helper function to determine if the URL is an image
const isImage = (url) => /\.(jpg|jpeg|png)$/i.test(url);

// Helper function to determine if the URL is a video
const isVideo = (url) => /\.(mp4|avi|mov|mkv|webm)$/i.test(url);

const Hero = () => {
  const [speakerData, setSpeakerData] = useState([]);

  const fetchSpeaker = async () => {
    axiosGet
      .get(`speaker/get/website?status=${1}`)
      .then((response) => {
        setSpeakerData(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [openS, setOpenS] = useState(false);
  const [openA, setOpenA] = useState(false);
  const [openReadmore, setOpenReadmore] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [eventData, setEventData] = useState([]);

  const options = { dragFree: true, loop: true, slidesToScroll: "auto" };
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);
  const fetchBanner = async () => {
    axiosGet
      .get(`bannersection/get/website`)
      .then((response) => {
        let data = response.data.data[0];
        setBannerData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const fetchEvent = async () => {
    axiosGet
      .get(`eventsection/get/web`)
      .then((response) => {
        let data = response.data.data[0];
        setEventData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchEvent();
    fetchSpeaker();
    fetchBanner();
  }, []);

  const isMediaImage = isImage(eventData?.media);
  const isMediaVideo = isVideo(eventData?.media);
  const [isContentOverflow, setIsContentOverflow] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const { scrollHeight } = contentRef.current;
        setIsContentOverflow(scrollHeight > 350);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [eventData.description]);

  const eventName = `Condition Monitoring and Reliability Engineer's Event`;

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;
    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop;
    resetOrStop();
  }, []);

  const {
    prevBtnDisabled: prevBtnDisabled,
    nextBtnDisabled: nextBtnDisabled,
    onPrevButtonClick: onPrevButtonClick,
    onNextButtonClick: onNextButtonClick,
  } = usePrevNextButtons(emblaApi, onNavButtonClick);

  return (
    <div id="home">
      <div className="bg-white">
        {/* Hero section Start */}

        <section className="mx-auto max-w-7xl flex flex-col-reverse items-center gap-5 md:gap-0 lg:flex-row px-8 py-20">
          <div className="mx-auto max-w-3xl lg:mx-0 h-full grid place-items-center">
            <div>
              <div
                className="mb-4 text-2xl md:text-4xl text-[#FF6500] leading-10"
                style={{ fontFamily: "custom" }}
              >
                {/* font-family: 'Cooper-Std';*/}
                <p className="">{eventName}</p>

                <span className="text-2xl md:text-4xl text-black italic block ">
                  Hyderabad!
                </span>
              </div>
              <p className="pe-lg-10 mb-4 text-slate-600">
                CORE India
                <br />
                {bannerData.fromDate} – {bannerData.toDate}
              </p>
              <div className="flex flex-col gap-1 text-wrap w-full">
                {/* <div
                    style={{ maxHeight: "160px", overflow: "auto" }}
                    className="aScrollBar"
                    dangerouslySetInnerHTML={{ __html: bannerData.keyStreamOne }}
                  /> */}
                {bannerData.keyStreamOne ? (
                  <div
                    style={{ maxHeight: "160px", overflow: "auto" }}
                    className="aScrollBar"
                    dangerouslySetInnerHTML={{
                      __html: bannerData.keyStreamOne,
                    }}
                  />
                ) : null}
                {bannerData.keyStreamTwo ? (
                  <div
                    style={{ maxHeight: "160px", overflow: "auto" }}
                    className="aScrollBar"
                    dangerouslySetInnerHTML={{
                      __html: bannerData.keyStreamTwo,
                    }}
                  />
                ) : null}
                {bannerData.keyStreamThree ? (
                  <div
                    style={{ maxHeight: "160px", overflow: "auto" }}
                    className="aScrollBar"
                    dangerouslySetInnerHTML={{
                      __html: bannerData.keyStreamThree,
                    }}
                  />
                ) : null}
                {bannerData.keyStreamFour ? (
                  <div
                    style={{ maxHeight: "160px", overflow: "auto" }}
                    className="aScrollBar"
                    dangerouslySetInnerHTML={{
                      __html: bannerData.keyStreamFour,
                    }}
                  />
                ) : null}
                {bannerData.keyStreamFive ? (
                  <div
                    style={{ maxHeight: "160px", overflow: "auto" }}
                    className="aScrollBar"
                    dangerouslySetInnerHTML={{
                      __html: bannerData.keyStreamFive,
                    }}
                  />
                ) : null}
                {/* {bannerData.keyStreamOne ? (
                    <p className="w-full text-wrap">
                      {" "}
                      {bannerData.keyStreamOne}
                    </p>
                  ) : null} 
                  {bannerData.keyStreamTwo ? (
                    <p className="w-full text-wrap">
                      {" "}
                      {bannerData.keyStreamTwo}
                    </p>
                  ) : null}
                  {bannerData.keyStreamThree ? (
                    <p className="w-full text-wrap">
                      {" "}
                      {bannerData.keyStreamThree}
                    </p>
                  ) : null}
                  {bannerData.keyStreamFour ? (
                    <p className="w-full text-wrap">
                      {" "}
                      {bannerData.keyStreamFour}
                    </p>
                  ) : null}
                  {bannerData.keyStreamFive ? (
                    <p className="w-full text-wrap">
                      {" "}
                      {bannerData.keyStreamFive}
                    </p>
                  ) : null}*/}
              </div>
            </div>
          </div>
          <img alt="" src="images/banner.png" className="  w-full max-w-2xl" />
        </section>
        {/* Hero section End */}

        {/* About section Start */}
        <section className="py-4 px-8 md:px-0 bg-[#fff4eb]">
          <div
            className="text-center global-title my-5"
            style={{ fontFamily: "custom" }}
          >
            <h2 className="inline-block font-extrabold text-xl md:text-3xl text-black ">
              About this event
            </h2>
          </div>
          <div className="overflow-hidden md:px-4 max-w-7xl mx-auto">
            <div className="mx-auto container grid max-w-2xl grid-cols-1 lg:gap-x-8 gap-y-2 md:gap-y-12 lg:mx-0 lg:max-w-none lg:grid-cols-5 lg:items-center md:min-h-[70vh]">
              <div className="col-span-2">
                {/* <EmblaCarousel slides={SLIDES} options={OPTIONS} /> */}
                <section className="embla z-10 relative">
                  {eventData?.media?.length > 1 ? (
                    <div
                      className="embla__controls flex justify-between items-center w-full absolute left-0 right-0 top-1/2 transform -translate-y-1/2 z-10"
                      style={{ marginTop: 0 }}
                    >
                      <div
                        className="embla__buttons w-full flex justify-between "
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="rounded-lg">
                          <PrevButton
                            onClick={onPrevButtonClick}
                            disabled={prevBtnDisabled}
                          />
                        </div>
                        <div className="rounded-lg">
                          <NextButton
                            onClick={onNextButtonClick}
                            disabled={nextBtnDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="embla__viewport pb-8" ref={emblaRef}>
                    <div className="embla__container">
                      {eventData.media?.map((item, index) => (
                        <div
                          className="embla__slide__hero cursor-pointer"
                          key={index}
                        >
                          <div className="embla__slide__number h-full py-4 w-full">
                            <div className="bg-white h-full w-[90%] relative shadow-lg hover:shadow-xl transition duration-300 rounded-lg mx-2 md:mx-auto cursor-pointer group grid place-items-center">
                              <img
                                className="rounded-lg aspect-video object-fill object-center "
                                src={
                                  item
                                    ? `${API_ENDPOINT_IMG}${item}`
                                    : `https://imgs.search.brave.com/AcKEz3k7iaIkv3ccVxR4dUQUOAS_wdEqGvbwPXRB4O8/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9waGxl/YXJuLmNvbS93cC1j/b250ZW50L3VwbG9h/ZHMvMjAxOS8wMy9y/YXdwaXhlbC1jb20t/MzY5Nzc1LXVuc3Bs/YXNoLmpwZw`
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
                {/* {isMediaImage && (
                  <img
                    alt="Product screenshot"
                    src={eventData?.media}
                    className=" rounded-xl drop-shadow-xs max-h-96  md:-mr-4 lg:mr-0"
                  />
                )}
                {isMediaVideo && (
                  <video
                    autoPlay
                    muted
                    //  controls
                    className="w-full rounded-xl drop-shadow-lg sm:w-[57rem] md:-mr-4 lg:mr-0"
                  >
                    <source
                      src={eventData?.media}
                      type={`video/${eventData?.media.split(".").pop()}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                )} */}
              </div>
              <div className="col-span-3 h-full">
                  <div className="lg:max-w-2xl h-full">
                    <figure className="border-t lg:border-t-0 lg:border-l border-gray-200 pt-3 lg:pt-8 lg:p-0 px-3 lg:pl-8 text-gray-400 h-full flex flex-col justify-around">
                      {eventData ? (
                        <blockquote className="text-base leading-7 mb-3">
                          <div
                            ref={contentRef}
                            className={`text-[#000] leading-6 ${
                              isContentOverflow ? "text-clamp" : ""
                            }`}
                            dangerouslySetInnerHTML={{
                              __html: eventData.description,
                            }}
                          />
                          {isContentOverflow && (
                            <button
                              onClick={() => setOpenReadmore(true)}
                              className="text-[#FF6500]"
                            >
                              read more
                            </button>
                          )}
                        </blockquote>
                      ) : (
                        <div className="text-center pb-6">
                          No event right now...
                        </div>
                      )}
                      <div className="flex flex-col md:flex-row w-full gap-0.5 md:gap-6 items-center justify-between">
                        <button
                          onClick={() => setOpenA(true)}
                          className="inline-flex h-[50px] animate-shimmer items-center justify-center rounded-md border  bg-[linear-gradient(110deg,#FF6500,45%,#ff6600d4,55%,#ff66009f)] bg-[length:200%_100%] px-6 font-medium text-slate-50 transition-colors w-full md:w-5/12 "
                        >
                          {" "}
                          Register as attendee
                        </button>
                        <button
                          type="button"
                          onClick={() => setOpenS(true)}
                          className="inline-flex h-[50px] animate-shimmer items-center justify-center rounded-md border  bg-[linear-gradient(110deg,#FF6500,45%,#ff6600d4,55%,#ff66009f)] bg-[length:200%_100%] px-6 font-medium text-slate-50 transition-colors w-full md:w-5/12"
                        >
                          Register as speaker
                        </button>
                        {/* <button
                          type="button"
                          onClick={() => setOpenA(true)}
                          className="text-white bg-[#FF6500] hover:bg-[#000] focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 my-0.5 duration-200 w-full max-w-60"
                        >
                          Register as attendee
                        </button> */}
                        {/* <button
                          type="button"
                          onClick={() => setOpenS(true)}
                          className="text-white bg-[#FF6500] hover:bg-[#000] focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 my-0.5 duration-200 w-full max-w-60"
                        >
                          Register as speaker
                        </button> */}
                      </div>
                    </figure>
                  </div>
              </div>
            </div>
          </div>
        </section>
        {/* About section End */}
        <Speaker open={openS} setOpen={setOpenS} />
        <Attendee open={openA} setOpen={setOpenA} />
        <Readmore
          open={openReadmore}
          setOpen={setOpenReadmore}
          data={eventData}
          eventName={eventName}
        />
        {/* <Toast show={show} setShow={setShow} setMsg={sMsg} /> */}
      </div>
    </div>
  );
};

export default Hero;
