import React from 'react'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Navbar'
import Hero from '../Components/Sections/Hero'
import Carousel from '../Components/Sections/Carousel'
import Attendees from '../Components/Sections/Attendees'

const home = () => {
    return (
        <div>
            <Hero />
            <Carousel />
            <Attendees />
        </div>
    )
}

export default home
