import React from 'react';

const Imprint = () => {
    return (
        <>
        <main className='max-w-7xl mx-auto'>
        <section className="py-10">
                <h1 className="text-3xl font-bold text-[#043572] pb-4">Imprint</h1>

                <p className="mt-2">
                    <span className="text-[#043572] font-bold">Craftsman Storage Systems <br />
                        Industriestr. 3 <br />
                        70794 Filderstadt </span>
                </p>
                <p className="mt-2">
                    Tel.: +91 123 456789012 <br />
                    Fax: +91 123 456789012<br />
                    Email: info@craftsmanstorage.com
                </p>


                <p className="mt-2">
                    <span className="text-[#043572] font-bold">Opening hours / office hours</span><br />
                    Mon – Fri from 08:00 to 20:00 <br />
                    Our online shop and hotline is available 24/7 <br />
                    Website: <a href="https://www.craftsmanstorage.com/">https://www.craftsmanstorage.com/</a>
                </p>

                <h4 className="text-md font-bold text-[#043572] mt-8 list-disc">Copyright</h4>
                <p className="mt-4">Copyright craftsmanstorage Storage Solutions. All rights reserved. All texts, images,
                    graphics, sound, video and animation
                    files as well as their arrangements are subject to copyright and other laws protecting intellectual
                    property.
                    They may not be copied for commercial purposes or for distribution, nor may they be altered and used on
                    other
                    websites.</p>
        </section>
        </main>
        </>
    );
}

export default Imprint;
