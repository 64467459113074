// Pages/NotFound.jsx

const NotFound = () => {
  return (
    <>
      {/* <div className="h-[75vh]">
         <h1>404 - Not Found</h1>
         <p>The page you're looking for does not exist.</p>
       </div> */}
      <section class="flex items-center h-[85vh] p-16 dark:bg-gray-50 dark:text-gray-800">
        <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
          <div class="max-w-md text-center">
            <h2 class="mb-8 font-extrabold text-9xl dark:text-gray-400">
              <span class="sr-only">Error</span>404
            </h2>
            <p class="text-2xl font-semibold md:text-3xl mb-8">Sorry, we couldn't find this page.</p>
            <div>
              <a rel="noopener noreferrer" href="/" class="mt-4 mb-8 px-8 py-3 font-semibold rounded border border-orange-600 ">Back to homepage</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NotFound;
