"use client";

import { useEffect } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function PricingToast({ show, setShow, msg, error }) {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setShow(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [show, setShow]);

  const bgColor = error ? "bg-[#FFE9E9]" : "bg-[#D1FFC9]";
  const text = error ? "text-[#DF3B3B]" : "text-[#158403]";

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed top-12 left-1/2 transform -translate-x-1/2 flex items-end px-4 py-6 z-50 w-full"
      >
        <div className="flex w-full flex-col items-center">
          <Transition
            show={show}
            enter="transition ease-out duration-300"
            enterFrom="transform translate-y-2 opacity-0"
            enterTo="transform translate-y-0 opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="transform translate-y-0 opacity-100"
            leaveTo="transform translate-y-2 opacity-0"
          >
            <div
              className={`pointer-events-auto w-full max-w-lg overflow-hidden rounded-lg ${bgColor} shadow-lg ring-1 ring-black ring-opacity-5`}
            >
              <div className="p-4">
                <div className="flex items-center">
                  <div className="flex w-0 flex-1 justify-between">
                    <p className={`w-0 flex-1 text-sm font-medium ${text} text-center`}>
                      {msg}
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false);
                      }}
                      className="inline-flex rounded-md bg-transparent text-gray-600 hover:text-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
